import http from '../util/service'
const path = 'job'

export const listJobs = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export const startJob = async (id) => {
  try {
    const { data } = await http.post(path, { id })
    return data
  } catch (err) {
    return false
  }
}

export default {
  listJobs,
  startJob
}
