import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../state/reducers'
import services from '../service'
import { composeWithDevTools } from 'redux-devtools-extension'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['profile', 'gallery', 'share', 'family', 'chat']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk.withExtraArgument(services))))

const persistor = persistStore(store)

export default [store, persistor]
