import * as actionTypes from '../actions/message'

export default (state = {
  isRequesting: false,
  messages: []
}, action) => {
  switch (action.type) {
    case actionTypes.MESSAGES_RECEIVED:
      return {
        ...state,
        messages: action.data.messages
      }
    default:
      return state
  }
}
