export const USER_VACCINATIONS_RECEIVE = 'USER_VACCINATIONS_RECEIVE'
export const ADD_USER_VACCINATION = 'ADD_USER_VACCINATION'
export const SET_USER_VACCINATION = 'SET_USER_VACCINATION'
export const REMOVE_USER_VACCINATION = 'REMOVE_USER_VACCINATION'

export const getUserVaccines = () => {
  return async (dispatch, _, { vaccination: vaccinationService }) => {
    try {
      const { data } = await vaccinationService.getUserVaccines()
      dispatch({
        type: USER_VACCINATIONS_RECEIVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const saveUserVaccine = (userVaccination) => {
  return async (dispatch, _, { vaccination: vaccinationService }) => {
    try {
      const { data } = await vaccinationService.saveUserVaccination(userVaccination)
      dispatch({
        type: userVaccination._id ? SET_USER_VACCINATION : ADD_USER_VACCINATION,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const deleteUserVaccination = (id) => {
  return async (dispatch, _, { vaccination: vaccinationService }) => {
    try {
      await vaccinationService.deleteUserVaccination(id)
      dispatch({
        type: REMOVE_USER_VACCINATION,
        data: id
      })
      return true
    } catch (err) {
      return false
    }
  }
}
