import http from '../util/service'
const path = 'gallery'

export const getGalleries = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export const sortIndex = async (sortOrder) => http.post(`${path}/sort`, { sortOrder })
export const createShareLink = async ({ galleryId, items }) => http.post(`${path}/share`, { galleryId, items })
export const addGallery = async (galleryData) => http.post(path, galleryData)
export const getLastDraft = async () => http.get(`${path}/get-last-draft`)
export const updateGallery = async (galleryId, title, viewPermissions) => {
  try {
    const { data } = await http.put(`${path}/${galleryId}`, { title, viewPermissions })
    return data
  } catch (err) {
    return false
  }
}

export const deleteGalleryItem = async (id, itemId) => {
  try {
    const { data } = await http.delete(`${path}/${id}/items/${itemId}`)
    return data
  } catch (err) {
    return false
  }
}

export const addGalleryItem = async (galleryId, file) => {
  /* eslint-disable no-undef */
  const formData = new FormData()
  formData.append('file', file)
  try {
    const { data } = await http.post(`${path}/${galleryId}`, formData)
    return data
  } catch (err) {
    return false
  }
}

export const updateGalleryItemField = async ({ galleryId, itemId, field, value }) => {
  try {
    const { data } = await http.post(`${path}/${galleryId}/items/${itemId}/field`, { field, value })
    return data
  } catch (err) {
    return false
  }
}

export const deleteGallery = async (id) => {
  try {
    const { data } = await http.delete(`${path}/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export default {
  getGalleries,
  addGallery,
  updateGallery,
  addGalleryItem,
  deleteGalleryItem,
  deleteGallery,
  getLastDraft,
  sortIndex,
  createShareLink,
  updateGalleryItemField
}
