import http from '../util/service'

const path = 'attachment'
export const getAttachments = async (module, userId, batchId = '') => {
  try {
    const { data } = await http.get(`${path}?module=${module}&user=${userId}&batchId=${batchId}`)
    return data
  } catch (err) {
    return false
  }
}

export const createAttachment = async (attachment) => {
  try {
    /* eslint-disable no-undef */
    const formData = new FormData()
    formData.append('file', attachment.file)
    formData.append('module', attachment.module)
    formData.append('title', attachment.title || '')
    formData.append('type', attachment.type)
    formData.append('user', attachment.user)
    formData.append('batchId', attachment.batchId)

    const { data } = await http.post(path, formData)
    return data
  } catch (err) {
    return false
  }
}

export const downloadAttachment = async (id) => {
  try {
    const data = await http.get(`${path}/${id}/download`)
    return data
  } catch (err) {
    return false
  }
}

export const updateAttachment = async (id, title, expiration) => {
  try {
    const { data } = await http.put(`${path}/${id}`, {
      title,
      expiration
    })
    return data
  } catch (err) {
    return false
  }
}

export const setAttachmentShared = async (id, isShared) => {
  try {
    const { data } = await http.post(`${path}/${id}`, {
      isShared
    })
    return data
  } catch (err) {
    return false
  }
}

export const deleteAttachment = async id => {
  try {
    await http.delete(`${path}/${id}`)
    return true
  } catch (err) {
    return false
  }
}

export default {
  getAttachments,
  createAttachment,
  updateAttachment,
  deleteAttachment,
  setAttachmentShared,
  downloadAttachment
}
