export const getRandomColor = (light = true) => {
  let letters
  if (light) {
    letters = '789ABCD'
  } else {
    letters = '0123456'
  }

  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 7)]
  }
  return color
}

export const humanFileSize = (bytes, si = true, truncate = false) => {
  const thresh = 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return `${truncate ? bytes : bytes.toFixed(2)} ${units[u]}`
}

export const stripHTML = (mixed) => {
  /* eslint-disable no-undef */
  const doc = new DOMParser().parseFromString(mixed, 'text/html')
  return doc.body.textContent || ''
}

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const clean = (obj) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const randomNumber = (length) => {
  let text = ''
  const possible = '123456789'
  for (let i = 0; i < length; i++) {
    const sup = Math.floor(Math.random() * possible.length)
    text += i > 0 && sup === i ? '0' : possible.charAt(sup)
  }
  return Number(text)
}

export const isImage = (mime) => ['image/gif', 'image/jpeg', 'image/png'].includes(mime)
export const isVideo = (mime) => ['video/mp4', 'video/3gpp', 'video/x-msvideo', 'video/quicktime'].includes(mime)
export const isDocument = (mime) => [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'application/pdf'
].includes(mime)

export const intToString = (value) => {
  const suffixes = ['', 'k', 'm', 'b', 't']
  const suffixNum = Math.floor(('' + value).length / 3)
  let shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2))
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1)
  }
  return shortValue + suffixes[suffixNum]
}
