import http from '../util/service'

const path = 'profile'

export const getProfile = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export const uploadProfilePhoto = async (file) => {
  try {
    /* eslint-disable no-undef */
    const formData = new FormData()
    formData.append('file', file)
    const { data: { url } } = await http.post(`${path}/photo`, formData)
    return url
  } catch (err) {
    return false
  }
}

export const getPhoto = async () => {
  const { data: { url } } = await http.get(`${path}/photo`)
  return url
}

export const updateProfile = (profile) => {
  /* eslint-disable no-undef */
  const formData = new FormData()
  Object.keys(profile).map(key => formData.append(key, key === 'importantDates' ? JSON.stringify(profile[key]) : profile[key]))
  return http.put(path, formData)
}

export const updateProfileField = (field, value, id) => http.put(`${path}/field`, { field, value, id })

export default {
  getProfile,
  updateProfile,
  uploadProfilePhoto,
  getPhoto,
  updateProfileField
}
