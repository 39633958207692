import axios from 'axios'
import localStorage from 'local-storage'
import config from '../config'
// import axiosRetry from 'axios-retry'

const { apiURL } = config

const DEFAULT_REQUEST_TIMEOUT = 60000
// const MAX_RETRY_REQUEST = 2
const service = axios.create({
  baseURL: `${apiURL}/`,
  timeout: DEFAULT_REQUEST_TIMEOUT
})

// axiosRetry(service, {
//   retries: MAX_RETRY_REQUEST,
//   retryDelay: axiosRetry.exponentialDelay
// })

service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers.Authorization = `Bearer ${localStorage.get('token')}`
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.replace('/')
  }
  return {
    data: [],
    success: false,
    message: error.message
  }
})

export default service
