export const JOBS_RECEIVE = 'JOBS_RECEIVE'
export const SET_JOB = 'SET_JOB'

export const listJobs = () => {
  return async (dispatch, _, { job: jobsService }) => {
    const data = await jobsService.listJobs()
    try {
      dispatch({
        type: JOBS_RECEIVE,
        data: data
      })
    } catch (err) {
      return false
    }
  }
}

export const startJob = (id) => {
  return async (dispatch, _, { job: jobsService }) => {
    await jobsService.startJob(id)
    dispatch(listJobs())
  }
}
