import * as actionTypes from '../actions/job'

export default (state = {
  jobs: []
}, action) => {
  switch (action.type) {
    case actionTypes.JOBS_RECEIVE:
      return {
        ...state,
        jobs: action.data
      }
    case actionTypes.SET_JOB:
      return {
        ...state,
        jobs: state.jobs.map(job =>
          job._id === action.data._id ? { ...job, ...action.data } : job)
      }
    default:
      return state
  }
}
