export const PROFILE_RECEIVE = 'PROFILE_RECEIVE'
export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO'
export const SET_PROFILE_PHOTO_STATUS = 'SET_PROFILE_PHOTO_STATUS'
export const SET_PROFILE_REQUESTING = 'SET_PROFILE_REQUESTING'
export const SET_PHOTO_REQUESTING = 'SET_PHOTO_REQUESTING'
export const SET_PROFILE_FIELD = 'SET_PROFILE_FIELD'
export const SET_PROFILE_MOBILE = 'SET_PROFILE_MOBILE'
export const SET_PROFILE_BIRTHDAY = 'SET_PROFILE_BIRTHDAY'
export const SET_FAMILY_PROFILE_FIELD = 'SET_FAMILY_PROFILE_FIELD'

export const setProfilePhotoStatus = (status) => ({
  type: SET_PROFILE_PHOTO_STATUS,
  data: status
})

export const setProfileRequesting = (isRequesting) => ({
  type: SET_PROFILE_REQUESTING,
  data: isRequesting
})

export const setPhotoRequesting = (isRequesting) => ({
  type: SET_PHOTO_REQUESTING,
  data: isRequesting
})

export const profileReceive = (profile) => ({
  type: PROFILE_RECEIVE,
  data: profile
})

export const getProfile = () => {
  return async (dispatch, _, { profile: profileService }) => {
    dispatch(setProfileRequesting(true))
    const profile = await profileService.getProfile()
    try {
      dispatch(profileReceive(profile))
    } catch (err) {
      return false
    } finally {
      dispatch(setProfileRequesting(false))
    }
  }
}

export const updateMobileNumber = (mobileNumber, id, familyId) => {
  return async (dispatch, _, { profile: profileService }) => {
    return profileService.updateProfileField('mobileNumber', mobileNumber, id)
      .then(() => {
        dispatch({
          type: id ? SET_FAMILY_PROFILE_FIELD : SET_PROFILE_FIELD,
          data: {
            fieldName: 'mobileNumber',
            fieldValue: mobileNumber,
            id,
            familyId
          }
        })
      })
      .catch(() => {
        console.log('field error')
      })
  }
}

export const updateBirthday = (birthday, id, familyId) => {
  return async (dispatch, _, { profile: profileService }) => {
    return profileService.updateProfileField('birthday', birthday, id)
      .then(() => {
        dispatch({
          type: id ? SET_FAMILY_PROFILE_FIELD : SET_PROFILE_FIELD,
          data: {
            fieldName: 'birthday',
            fieldValue: birthday,
            id,
            familyId
          }
        })
      })
      .catch(() => {
        console.log('field error')
      })
  }
}

export const uploadProfilePhoto = (_, file) => {
  return async (dispatch, getState, { profile: profileService }) => {
    return profileService.uploadProfilePhoto(file)
      .then((url) => {
        dispatch({
          type: SET_PROFILE_PHOTO,
          data: url
        })
        return url
      })
      .catch(() => {
        return false
      })
  }
}

export const getPhoto = () => {
  return async (dispatch, getState, { profile: profileService }) => {
    dispatch(setPhotoRequesting(true))
    return profileService.getPhoto()
      .then(({ url }) => {
        dispatch({
          type: SET_PROFILE_PHOTO,
          data: url
        })
      })
      .catch(() => {
        return false
      })
      .finally(() => {
        dispatch(setPhotoRequesting(false))
      })
  }
}

export const updateProfile = (profile) => {
  return async (dispatch, getState, { profile: profileService }) => {
    delete profile.profilePicture
    delete profile.profilePictureStatus
    delete profile.isPhotoRequesting
    delete profile.isProfileRequesting
    delete profile.isNewUser
    delete profile.invitedBy

    return profileService.updateProfile(profile)
      .then(({ data }) => {
        dispatch(profileReceive(data))
        return true
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}

export const updateProfileField = ({ fieldName, fieldValue, id, familyId }) => {
  return async (dispatch, getState, { profile: profileService }) => {
    return profileService.updateProfileField(fieldName, fieldValue, id)
      .then(() => {
        dispatch({
          type: id ? SET_FAMILY_PROFILE_FIELD : SET_PROFILE_FIELD,
          data: {
            fieldName,
            fieldValue,
            familyId,
            id
          }
        })
      })
      .catch(() => {
        console.log('field error')
      })
  }
}
