import * as actionTypes from '../actions/attachment'

export default (state = {
  attachments: [],
  batchId: ''
}, action) => {
  switch (action.type) {
    case actionTypes.SET_BATCH_ID:
      return {
        ...state,
        batchId: action.data
      }
    case actionTypes.ATTACHMENTS_RECEIVE:
      return {
        ...state,
        attachments: action.data
      }
    case actionTypes.PUSH_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.data]
      }
    case actionTypes.SET_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map(attachment =>
          attachment._id === action.data._id ? { ...attachment, ...action.data } : attachment)
      }
    case actionTypes.REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(x => x._id !== action.id)
      }
    case actionTypes.SET_SHARED:
      return {
        ...state,
        attachments: state.attachments.map(attachment =>
          attachment._id === action.data.id ? { ...attachment, isShared: action.data.isShared } : attachment)
      }
    default:
      return state
  }
}
