import * as actionTypes from '../actions/profile'

export default (state = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  birthday: '',
  bloodType: '',
  city: '',
  country: '',
  profilePicture: '',
  profilePictureStatus: 0,
  isPhotoRequesting: false,
  isProfileRequesting: false
}, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_FIELD:
      return {
        ...state,
        [action.data.fieldName]: action.data.fieldValue
      }
    case actionTypes.PROFILE_RECEIVE:
      return {
        ...state,
        ...action.data
      }
    case actionTypes.SET_PROFILE_REQUESTING:
      return {
        ...state,
        isProfileRequesting: action.data
      }
    case actionTypes.SET_PHOTO_REQUESTING:
      return {
        ...state,
        isPhotoRequesting: action.data
      }
    case actionTypes.SET_PROFILE_PHOTO_STATUS:
      return {
        ...state,
        profilePictureStatus: action.data
      }
    case actionTypes.SET_PROFILE_PHOTO:
      return {
        ...state,
        profilePicture: action.data
      }
    default:
      return state
  }
}
