import http from '../util/service'
const path = 'faq'

export const getFaqs = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export default {
  getFaqs
}
