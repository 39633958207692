export const FAQS_RECEIVE = 'FAQS_RECEIVE'
export const ADD_FAQ = 'ADD_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'
export const SET_FAQ = 'SET_FAQ'

export const faqsReceive = (faqs) => ({
  type: FAQS_RECEIVE,
  data: faqs
})

export const listFaqs = () => {
  return async (dispatch, _, { faq: faqService }) => {
    const data = await faqService.getFaqs()
    try {
      dispatch(faqsReceive(data))
    } catch (err) {
      return false
    }
  }
}
