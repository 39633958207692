export const FAMILIES_RECEIVE = 'FAMILIES_RECEIVE'
export const ADD_FAMILY = 'ADD_FAMILY'
export const SET_FAMILY = 'SET_FAMILY'
export const DELETE_FAMILY = 'DELETE_FAMILY'
export const SET_FAMILY_PHOTO = 'SET_FAMILY_PHOTO'
export const SET_CONTACT_PRESENCE = 'SET_CONTACT_PRESENCE'
export const PRESENCES_RECEIVE = 'PRESENCES_RECEIVE'

export const familiesReceive = (families) => ({
  type: FAMILIES_RECEIVE,
  data: families
})

export const setContactPresence = (isConnected, userId) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONTACT_PRESENCE,
      data: {
        isConnected,
        userId
      }
    })
  }
}

export const getPresences = () => {
  return async (dispatch, _, { family: familyService }) => {
    const data = await familyService.getPresences()
    try {
      for (const presence of data) {
        dispatch(setContactPresence(presence.isConnected, presence._id))
      }
    } catch (err) {
      return []
    }
  }
}

export const listFamilies = (force = true) => {
  return async (dispatch, getState, { family: familyService }) => {
    if (!force) {
      const { family: { families } } = getState()
      if (families.length > 0) {
        return
      }
    }
    const data = await familyService.getFamilies()
    try {
      dispatch(familiesReceive(data))
    } catch (err) {
      return []
    }
  }
}

export const addFamily = (familyData) => {
  return async (dispatch, _, { family: familyService }) => {
    return familyService.addFamily(familyData)
      .then(({ data }) => {
        dispatch({
          type: ADD_FAMILY,
          data
        })
        return true
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}

export const updateFamily = (familyData) => {
  return async (dispatch, _, { family: familyService }) => {
    delete familyData.user.profilePicture
    delete familyData.user.lastSeen
    delete familyData.user.attachments

    return familyService.updateFamily(familyData)
      .then(({ data }) => {
        dispatch({
          type: SET_FAMILY,
          data
        })
        return true
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}

export const updateFamilyPhoto = (id, file) => {
  return async (dispatch, getState, { family: familyService }) => {
    return familyService.updateFamilyPhoto(id, file)
      .then((url) => {
        dispatch({
          type: SET_FAMILY_PHOTO,
          data: {
            id,
            url
          }
        })
        return url
      })
      .catch(() => false)
  }
}

export const deleteFamily = (id) => {
  return async (dispatch, _, { family: familyService }) => {
    return familyService.deleteFamily(id)
      .then(() => {
        dispatch({
          type: DELETE_FAMILY,
          data: id
        })
      })
      .catch(() => false)
  }
}

export const getFamilyDetails = (id) => {
  return async (dispatch, getState, { family: familyService }) => {
    return familyService.getFamilyMemberDetail(id)
      .then((data) => {
        const { family: { families } } = getState()
        dispatch({
          type: families.length === 0 ? ADD_FAMILY : SET_FAMILY,
          data
        })
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}
