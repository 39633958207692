import React, { Fragment, useState, useEffect } from 'react'
import Logo from '../components/common/logo'
import ProfileHeadline from '../components/common/profileHeadline'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import menuItems from '../json/menu'
import Divider from '../components/common/divider'
import { useScreenClass } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faWindowClose, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import localStorage from 'local-storage'
import { verifyToken } from '../state/actions/auth'
import { getProfile } from '../state/actions/profile'
import { listFamilies, getPresences } from '../state/actions/family'
import { getPlans, getSettings, getCountries, getSocketNamespace, getVaccinations } from '../state/actions/setting'
import { getGroups, getGroupMessages, getReadReceipts } from '../state/actions/chat'
import isEmpty from 'lodash/isEmpty'
import { ToastContainer } from 'react-toastify'
import { detect } from 'detect-browser'

import 'react-toastify/dist/ReactToastify.css'
import './dashboard.scss'
import Dock from 'react-dock'

import initSocket from '../socket'

export default ({ children }) => {
  const browser = detect()
  const location = useLocation()
  const userId = useSelector(({ profile: { _id } }) => _id)
  const status = useSelector(({ auth: { profile: { status } } }) => status)
  const {
    _id,
    firstName,
    profilePicture
  } =
    useSelector(({
      profile: {
        _id,
        firstName,
        profilePicture
      }
    }) => ({
      _id,
      firstName,
      profilePicture
    }))

  const unreadCount = useSelector(({ chat: { messages, receipts, currentGroup } }) => {
    return Object.keys(messages).filter(x => x !== currentGroup).reduce((acc, key) => {
      const msgs = (messages[key] || []).filter(x => {
        if (x.type === 'system') {
          return false
        }
        return x.user !== userId
      })
      const lastMessage = msgs[msgs.length - 1] || {}
      const lastRead = receipts[lastMessage.chatroom]
      if (!lastRead) {
        acc += msgs.length
        return acc
      }
      const idxLastRead = msgs.findIndex(x => x._id === lastRead)
      if (idxLastRead < 0) {
        return acc
      }
      acc += (msgs.length - idxLastRead - 1)
      return acc
    }, 0)
  })

  const room = useSelector(({ setting: { room } }) => room)

  const [hasRequested, setHasRequested] = useState(false)

  const [plans, countries] = useSelector(({ setting: { plans, countries } }) => [plans, countries])

  const menuFiltered = menuItems.filter(m => m.permission.includes(status))

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [socket, setSocket] = useState()
  const size = useScreenClass()
  const history = useHistory()
  const dispatch = useDispatch()

  const onHideSidebar = () => setMobileMenuOpen(false)
  const onToggleSidebar = () => setMobileMenuOpen((x) => !x)

  const onLogout = (e) => {
    socket.emit('logout')
    e.preventDefault()
    localStorage.clear()
    window.location.replace('/')
  }

  const onGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isEmpty(_id) && !hasRequested) {
      dispatch(getProfile())
      dispatch(getSettings())
      dispatch(getSocketNamespace())
      dispatch(getVaccinations())

      if (isEmpty(plans)) {
        dispatch(getPlans())
      }

      if (isEmpty(countries)) {
        dispatch(getCountries())
      }
      setHasRequested(true)
    }
  }, [history, dispatch, _id, hasRequested])

  useEffect(() => {
    const verify = async () => {
      const { status } = await dispatch(verifyToken())
      if (status !== 200) {
        history.push('/')
      }
    }
    verify()
  }, [dispatch, history])

  useEffect(() => {
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    const root = document.getElementById('root')
    root.style.height = `${height}px`
  }, [])

  useEffect(() => {
    if (browser.name === 'firefox' && !document.body.classList.contains('firefox')) {
      document.body.className += ' firefox'
    }
  }, [browser])

  useEffect(() => {
    const task = async () => {
      dispatch(listFamilies(false))
      const groups = await dispatch(getGroups(false))
      await Promise.all(groups.map(async g => dispatch(getGroupMessages(g._id, false))))
    }
    task()
  }, [])

  useEffect(() => {
    if (room) {
      console.log('initializing socket with ', room)
      setSocket(initSocket())
    }
  }, [room])

  useEffect(() => {
    dispatch(getPresences())
    dispatch(getReadReceipts())
  }, [])

  return (
    <>
      <ToastContainer
        limit={1}
        position='top-right'
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        closeOnClick
        pauseOnHover
      />
      {['md', 'lg', 'xl', 'xxl'].includes(size) &&
        <div className='dashboard'>
          <div className={cx(
            'dashboard__sidebar'
          )}
          >
            <div className='dashboard__sidebar__logo'>
              <Logo useBlueBg />
            </div>
            <ul className='dashboard__sidebar__links'>
              {menuFiltered.map(({ name, link }, idx) =>
                <Fragment key={idx}>
                  {name !== 'divider' &&
                    <li className={cx({ 'item-active': location.pathname === link })}>
                      <Link to={link}>
                        {name === 'Chat' && unreadCount > 0 && <span className='dashboard__sidebar__links--unread'>{unreadCount}</span>}
                        {name}
                      </Link>
                    </li>}
                  {name === 'divider' && <li>&nbsp;</li>}
                </Fragment>
              )}
              <li><button onClick={onLogout}>Logout</button></li>
            </ul>
            <div className='dashboard__sidebar__footer'>
              <Link to='/tos'>Terms of Use</Link>
              <Link to='/privacy'>Privacy Policy</Link>
              {/* <Link to='/disclaimer'>Disclaimer</Link> */}
            </div>
          </div>
          <div className='dashboard__content'>
            <div className='dashboard__content__profile'><ProfileHeadline name={firstName} profilePicture={profilePicture} /></div>
            {children}
          </div>
        </div>}
      {['xs', 'sm'].includes(size) &&
        <div className='mdash'>
          <Dock position='right' isVisible={mobileMenuOpen} size={0.75}>
            <div className='mdash__mmenu__hc'>
              <div className='mdash__mmenu__header'>
                <FontAwesomeIcon icon={faWindowClose} size='2x' onClick={onHideSidebar} color='#fff' />
              </div>
            </div>
            <div className='mdash__mmenu'>
              {menuFiltered.map(({ name, link }, idx) =>
                <Fragment key={idx}>
                  {name !== 'divider' && <Link to={link} className={cx({ 'item-active': location.pathname === link })}>{name}</Link>}
                  {name === 'divider' && <Divider margin='5px 0' />}
                </Fragment>
              )}
              <a href='/' onClick={onLogout}>Logout</a>
            </div>
          </Dock>
          <div className='mdash__head'>
            {history.action === 'PUSH' &&
              <FontAwesomeIcon className='mdash__head__back' icon={faChevronLeft} size='2x' onClick={onGoBack} color='#fff' />}
            <Logo height='30px' useBlueBg />
            <FontAwesomeIcon icon={faBars} style={{ cursor: 'pointer' }} size='2x' color='#fff' onClick={onToggleSidebar} />
          </div>
          <div className='mdash__content'>
            {children}
          </div>
        </div>}
    </>
  )
}
