export const ATTACHMENTS_RECEIVE = 'ATTACHMENTS_RECEIVE'
export const SET_ATTACHMENT = 'SET_ATTACHMENT'
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT'
export const SET_SHARED = 'SET_SHARED'
export const SET_BATCH_ID = 'SET_BATCH_ID'
export const PUSH_ATTACHMENT = 'PUSH_ATTACHMENT'

export const setBatchId = (batchId) => ({
  type: SET_BATCH_ID,
  data: batchId
})

export const attachmentsReceive = (attachments) => ({
  type: ATTACHMENTS_RECEIVE,
  data: attachments
})

export const listAttachments = (module, userId, batchId) => {
  return async (dispatch, _, { attachment: attachmentService }) => {
    const data = await attachmentService.getAttachments(module, userId, batchId)
    try {
      dispatch(attachmentsReceive(data))
    } catch (err) {
      return false
    }
  }
}

export const createAttachment = attachment => {
  return async (dispatch, _, { attachment: attachmentService }) => {
    try {
      const data = await attachmentService.createAttachment(attachment)
      dispatch({
        type: PUSH_ATTACHMENT,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const downloadAttachment = (id) => {
  return async (dispatch, getState, { attachment: attachmentService }) => {
    try {
      const data = await attachmentService.downloadAttachment(id)
      return data
    } catch (err) {
      return false
    }
  }
}

export const setAttachmentShared = (id, isShared, drawerConfig) => {
  return async (dispatch, getState, { attachment: attachmentService }) => {
    return attachmentService.setAttachmentShared(id, isShared)
      .then((data) => {
        if (data.module === 'drawer' && drawerConfig) {
          const { drawer: { drawers } } = getState()
          const drawer = drawers.find(x => x._id === drawerConfig.id)
          const item = drawer.items.find(x => x._id === drawerConfig.itemId)
          const attachment = item.attachments.find(x => x._id === data._id)
          attachment.title = (data.title + '').trim()
          attachment.expiration = data.expiration
          attachment.url = data.url
          attachment.isShared = data.isShared

          dispatch({
            type: 'SET_DRAWER_ITEM_ATTACHMENT',
            data: {
              drawerId: drawerConfig.id,
              itemId: drawerConfig.itemId,
              attachment
            }
          })
        } else {
          dispatch({
            type: SET_SHARED,
            data: {
              id,
              isShared
            }
          })
        }
        return true
      })
      .catch(() => false)
  }
}

export const updateAttachment = (id, title, expiration, drawerConfig) => {
  return async (dispatch, getState, { attachment: attachmentService }) => {
    return attachmentService.updateAttachment(id, title, expiration)
      .then((data) => {
        if (data.module === 'drawer' && drawerConfig) {
          const { drawer: { drawers } } = getState()
          const drawer = drawers.find(x => x._id === drawerConfig.id)
          const item = drawer.items.find(x => x._id === drawerConfig.itemId)
          const attachment = item.attachments.find(x => x._id === data._id)
          attachment.title = (data.title + '').trim()
          attachment.expiration = data.expiration
          attachment.url = data.url

          dispatch({
            type: 'SET_DRAWER_ITEM_ATTACHMENT',
            data: {
              drawerId: drawerConfig.id,
              itemId: drawerConfig.itemId,
              attachment
            }
          })
        } else {
          dispatch({
            type: SET_ATTACHMENT,
            data
          })
        }
        return true
      })
      .catch(() => false)
  }
}

export const deleteAttachment = (id) => {
  return async (dispatch, getState, { attachment: attachmentService }) => {
    return attachmentService.deleteAttachment(id)
      .then(() => {
        dispatch({
          type: REMOVE_ATTACHMENT,
          id
        })
      })
      .catch(() => false)
  }
}
