import * as actionTypes from '../actions/vaccination'

export default (state = {
  vaccinations: []
}, action) => {
  switch (action.type) {
    case actionTypes.USER_VACCINATIONS_RECEIVE:
      return {
        ...state,
        vaccinations: action.data
      }
    case actionTypes.ADD_USER_VACCINATION:
      return {
        ...state,
        vaccinations: [...state.vaccinations, { ...action.data }]
      }
    case actionTypes.SET_USER_VACCINATION:
      return {
        ...state,
        vaccinations: state.vaccinations.map(v =>
          v._id === action.data._id ? { ...v, ...action.data } : v)
      }
    case actionTypes.REMOVE_USER_VACCINATION:
      return {
        ...state,
        vaccinations: state.vaccinations.filter(v => v._id !== action.data)
      }
    default:
      return state
  }
}
