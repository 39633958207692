export const MESSAGES_RECEIVED = 'MESSAGES_RECEIVED'

export const messageReceived = (messages) => ({
  type: MESSAGES_RECEIVED,
  data: messages
})

// export const addMessage = (family) => ({
//   type: ADD_MESSAGE,
//   data: family
// })

// export const setMessage = (family) => ({
//   type: SET_MESSAGE,
//   data: family
// })

// export const setMessageRequesting = () => ({
//   type: MESSAGES_REQUEST_START
// })

// export const setMessageStopRequesting = () => ({
//   type: MESSAGES_REQUEST_END
// })

export const getMessagesSummary = () => {
  return async (dispatch, _, { message: messageService }) => {
    const data = await messageService.getMessagesSummary()
    try {
      dispatch(messageReceived(data))
    } catch (err) {
      return false
    }
  }
}
