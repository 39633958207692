import React from 'react'
import DashboardLayout from '../../layouts/dashboard'

export default (props) => {
  return (
    <DashboardLayout>
      <div>Loading, please wait...</div>
    </DashboardLayout>
  )
}
