export const GALLERIES_RECEIVE = 'GALLERIES_RECEIVE'
export const ADD_GALLERY = 'ADD_GALLERY'
export const SET_GALLERY = 'SET_GALLERY'
export const SET_GALLERY_PERMISSION = 'SET_GALLERY_PERMISSION'
export const SET_GALLERY_TITLE = 'SET_GALLERY_TITLE'
export const SET_GALLERY_STATUS = 'SET_GALLERY_STATUS'
export const SET_GALLERY_TITLE_DESC = 'SET_GALLERY_TITLE_DESC'
export const SET_GALLERY_ITEM_FIELD = 'SET_GALLERY_ITEM_FIELD'
export const SET_GALLERY_ITEM_TITLE = 'SET_GALLERY_ITEM_TITLE'
export const SET_GALLERY_ITEM_FAVORITE = 'SET_GALLERY_ITEM_FAVORITE'
export const SET_GALLERY_ITEM_PINNED = 'SET_GALLERY_ITEM_PINNED'
export const REMOVE_GALLERY = 'REMOVE_GALLERY'
export const REMOVE_GALLERY_ITEM = 'REMOVE_GALLERY_ITEM'
export const SET_SORT_INDEX = 'SET_SORT_INDEX'
export const ADD_GALLERY_ITEM = 'ADD_GALLERY_ITEM'

export const galleriesReceive = (galleries) => ({
  type: GALLERIES_RECEIVE,
  data: galleries
})

export const createShareLink = ({ galleryId, items }) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    try {
      const { data } = await galleryService.createShareLink({ galleryId, items })
      return data.url
    } catch (err) {
      return false
    }
  }
}

export const listGalleries = () => {
  return async (dispatch, _, { gallery: galleryService }) => {
    const data = await galleryService.getGalleries()
    try {
      dispatch(galleriesReceive(data))
    } catch (err) {
      return false
    }
  }
}

export const listShareGallery = (shareId) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    const data = await galleryService.listShareGallery(shareId)
    try {
      dispatch(galleriesReceive(data))
    } catch (err) {
      return false
    }
  }
}

export const addGallery = (gallery) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    return galleryService.addGallery(gallery)
      .then(({ data }) => {
        dispatch({
          type: ADD_GALLERY,
          data
        })
        return data._id
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}

export const deleteGallery = (id) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    return galleryService.deleteGallery(id)
      .then(() => {
        dispatch({
          type: REMOVE_GALLERY,
          data: {
            id
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const deleteGalleryItem = (galleryId, itemId) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    return galleryService.deleteGalleryItem(galleryId, itemId)
      .then(() => {
        dispatch({
          type: REMOVE_GALLERY_ITEM,
          data: {
            galleryId,
            itemId
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const updateGallery = (id, title, viewPermissions) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    return galleryService.updateGallery(id, title, viewPermissions)
      .then((gallery) => {
        dispatch({
          type: SET_GALLERY_PERMISSION,
          data: {
            viewPermissions
          }
        })
        dispatch({
          type: SET_GALLERY_TITLE,
          data: {
            title
          }
        })
        dispatch({
          type: SET_GALLERY_STATUS,
          data: {
            status: gallery.status
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const updateGalleryItemField = (args) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    return galleryService.updateGalleryItemField(args)
      .then(() => {
        dispatch({
          type: SET_GALLERY_ITEM_FIELD,
          data: {
            galleryId: args.galleryId,
            itemId: args.itemId,
            field: args.field,
            value: args.value
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const addGalleryItem = (galleryId, file) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    try {
      const data = await galleryService.addGalleryItem(galleryId, file)
      dispatch({
        type: ADD_GALLERY_ITEM,
        data: {
          galleryId,
          item: data
        }
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const sortIndex = (sortOrder) => {
  return async (dispatch, _, { gallery: galleryService }) => {
    try {
      await galleryService.sortIndex(sortOrder)
      dispatch({
        type: SET_SORT_INDEX,
        data: {
          sortOrder
        }
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getLastDraft = () => {
  return async (dispatch, getState, { gallery: galleryService }) => {
    return galleryService.getLastDraft()
      .then(({ data }) => {
        const { gallery: { galleries } } = getState()
        const draft = galleries.find(x => x.status === 'draft')
        if (draft) {
          dispatch({
            type: REMOVE_GALLERY,
            data: {
              id: draft._id
            }
          })
        }
        dispatch({
          type: ADD_GALLERY,
          data
        })
        return data
      })
      .catch(err => {
        console.log(err)
      })
  }
}
