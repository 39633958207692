import * as actionTypes from '../actions/faq'

export default (state = {
  faqs: []
}, action) => {
  switch (action.type) {
    case actionTypes.FAQS_RECEIVE:
      return {
        ...state,
        faqs: action.data
      }
    case actionTypes.ADD_FAQ:
      return {
        ...state,
        faqs: [...state.faqs, { ...action.data }]
      }
    case actionTypes.SET_FAQ:
      return {
        ...state,
        faqs: state.faqs.map(faq =>
          faq.id === action.data.id ? { ...faq, ...action.data } : faq)
      }
    case actionTypes.DELETE_FAQ:
      return {
        ...state,
        faqs: state.faqs.filter(faq => faq._id !== action.data)
      }
    default:
      return state
  }
}
