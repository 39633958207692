import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../static/logo-pendolia.png'
import LogoBlue from '../../static/logo-bluebg.png'
import { useScreenClass } from 'react-grid-system'

import './logo.scss'
export default ({ useBlueBg, width = 'auto', height = 'auto' }) => {
  const size = useScreenClass()
  return (
    <Link to='/' className={`logo-${size}`}>
      <img
        src={useBlueBg ? LogoBlue : Logo}
        alt='pendolia logo'
        style={{
          cursor: 'pointer',
          width,
          height
        }}
      />
    </Link>
  )
}
