const apiDevUrl = 'http://ec2-18-117-130-64.us-east-2.compute.amazonaws.com:3000/api'
const apiProdUrl = 'http://ec2-18-117-130-64.us-east-2.compute.amazonaws.com:3000/api'
const S3GalleryPath = 'pendolia-gallery'
const notificationTimeout = 30000

export default {
  apiURL: process.env.NODE_ENV === 'production' ? apiProdUrl : apiDevUrl,
  S3GalleryPath,
  notificationTimeout,
  wsUrl: process.env.NODE_ENV === 'production'
    ? 'http://ec2-18-117-130-64.us-east-2.compute.amazonaws.com:3000'
    : 'http://localhost:3000',
  tinyMceApiKey: '1b213tdw808t3afzvu72bxaoyz87gfnyx4ifgibuvtkaxyps'
}
