import * as actionTypes from '../actions/share'

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SHARE_LIST_RECEIVE:
      return {
        ...state,
        [action.data._id]: action.data
      }
    default:
      return state
  }
}
