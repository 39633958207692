import React from 'react'
import ProfilePicture from './profilePicture'
import './profileHeadline.scss'
import { useScreenClass } from 'react-grid-system'
import cx from 'classnames'

export default ({ name, profilePicture }) => {
  const size = useScreenClass()
  return (
    <div className={cx('profileHeadline', `profileHeadline-${size}`)}>
      <ProfilePicture picture={profilePicture} size={60} />
      <div className='profileHeadline__message'>Welcome {name}</div>
    </div>
  )
}
