import http from '../util/service'
const path = 'chat'
let isRequesting = false
export const saveGroup = async (group) => {
  try {
    const formData = new FormData()
    if (group.id) {
      formData.append('id', group.id)
    }
    formData.append('users', JSON.stringify(group.users.map(x => x)))
    formData.append('title', group.title)
    formData.append('isGroup', group.isGroup)
    formData.append('file', group.file)

    const { data } = await http.post(`${path}/groups`, group.file ? formData : group)
    return data
  } catch (err) {
    return false
  }
}

export const setLastMessageRead = async (groupId, messageId) => {
  if (isRequesting) {
    return
  }
  try {
    isRequesting = true
    const { data } = await http.post(`${path}/receipts`, {
      groupId,
      messageId
    })

    setTimeout(() => {
      isRequesting = false
    }, 1500)

    return data
  } catch (err) {
    setTimeout(() => {
      isRequesting = false
    }, 1500)

    return false
  }
}

export const addReaction = async (messageId, reaction) => {
  try {
    const { data } = await http.post(`${path}/messages/${messageId}/reactions`, {
      reaction
    })
    return data
  } catch (err) {
    return false
  }
}

export const getReadReceipts = async () => {
  try {
    const { data } = await http.get(`${path}/receipts`)
    return data
  } catch (err) {
    return false
  }
}

export const getGroups = async () => {
  try {
    const { data } = await http.get(`${path}/groups`)
    return data
  } catch (err) {
    return false
  }
}

export const getGroup = async (id) => {
  try {
    const { data } = await http.get(`${path}/groups/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export const getGroupMessages = async (id) => {
  try {
    const { data } = await http.get(`${path}/groups/${id}/messages`)
    return data
  } catch (err) {
    return false
  }
}

export const deleteGroup = async (id) => {
  try {
    const { data } = await http.delete(`${path}/groups/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export const createEmptyMessage = async (id) => {
  try {
    const { data } = await http.post(`${path}/groups/${id}/messages/empty`)
    return data
  } catch (err) {
    return false
  }
}

export const updateAttachmentTitle = async ({ groupId, messageId, attachmentId, title }) => {
  try {
    const { data } = await http.put(`${path}/messages/${messageId}/attachments/${attachmentId}`, { title })
    return data
  } catch (err) {
    return false
  }
}

export const addAttachment = async (id, file) => {
  try {
    /* eslint-disable no-undef */
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await http.post(`${path}/messages/${id}/attachments`, formData)
    return data
  } catch (err) {
    return false
  }
}

export default {
  saveGroup,
  getGroups,
  getGroup,
  getGroupMessages,
  deleteGroup,
  createEmptyMessage,
  addAttachment,
  updateAttachmentTitle,
  setLastMessageRead,
  getReadReceipts,
  addReaction
}
