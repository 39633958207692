import http from '../util/service'
const path = 'vaccinations'

export const getUserVaccines = async () => http.get(path)
export const saveUserVaccination = async (userVaccine) => http.post(path, { ...userVaccine })
export const deleteUserVaccination = async (id) => http.delete(`${path}/${id}`)

export default {
  getUserVaccines,
  saveUserVaccination,
  deleteUserVaccination
}
