import * as actionTypes from '../actions/family'
import { SET_FAMILY_PROFILE_FIELD } from '../actions/profile'

export default (state = {
  families: []
}, action) => {
  switch (action.type) {
    case SET_FAMILY_PROFILE_FIELD:
      return {
        ...state,
        families: state.families.map(family =>
          family._id === action.data.familyId
            ? { ...family, user: { ...family.user, [action.data.fieldName]: action.data.fieldValue } }
            : family)
      }
    case actionTypes.FAMILIES_RECEIVE:
      return {
        ...state,
        families: action.data
      }
    case actionTypes.ADD_FAMILY:
      return {
        ...state,
        families: [...state.families, { ...action.data }]
      }
    case actionTypes.SET_FAMILY:
      return {
        ...state,
        families: state.families.map(family =>
          family._id === action.data._id ? { ...family, ...action.data } : family)
      }
    case actionTypes.DELETE_FAMILY:
      return {
        ...state,
        families: state.families.filter(family => family._id !== action.data)
      }
    case actionTypes.SET_FAMILY_PHOTO:
      return {
        ...state,
        families: state.families.map(family =>
          family._id === action.data.id
            ? { ...family, user: { ...family.user, profilePicture: action.data.url } }
            : family)
      }
    case actionTypes.SET_CONTACT_PRESENCE:
      return {
        ...state,
        families: state.families.map(family =>
          family.user._id === action.data.userId
            ? { ...family, user: { ...family.user, isOnline: action.data.isConnected } }
            : family)
      }
    default:
      return state
  }
}
