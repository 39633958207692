import http from '../util/service'
const path = 'message'

export const getMessagesSummary = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export default {
  getMessagesSummary
}
