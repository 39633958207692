import * as actionTypes from '../actions/drawer'

export default (state = {
  drawers: []
}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DRAWER_ITEM_TITLE:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        title: action.data.title
                      }
                    : item)
              }
            : drawer)
      }
    case actionTypes.UPDATE_DRAWER_ITEM_DESC:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        description: action.data.desc
                      }
                    : item)
              }
            : drawer)
      }
    case actionTypes.UPDATE_DRAWER_TITLE_DESCRIPTION:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.id
            ? {
                ...drawer,
                title: action.data.title,
                description: action.data.description
              }
            : drawer)
      }
    case actionTypes.DRAWERS_RECEIVE:
      return {
        ...state,
        drawers: action.data
      }
    case actionTypes.ADD_DRAWER:
      return {
        ...state,
        drawers: [...state.drawers, { ...action.data }]
      }
    case actionTypes.SET_DRAWER:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.id ? { ...drawer, ...action.data } : drawer)
      }
    case actionTypes.SET_DRAWER_TITLE_DESC:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.id
            ? {
                ...drawer,
                title: action.data.title,
                description: action.data.description
              }
            : drawer)
      }
    case actionTypes.REMOVE_DRAWER_ITEM:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.filter(item => item._id !== action.data.itemId)
              }
            : drawer)
      }
    case actionTypes.ADD_DRAWER_ITEM:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: [...drawer.items, action.data.item]
              }
            : drawer)
      }
    case actionTypes.REMOVE_DRAWER:
      return {
        ...state,
        drawers: state.drawers.filter(drawer => drawer._id !== action.data.id)
      }
    case actionTypes.SET_DRAWER_ITEM_TITLE:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        title: action.data.title
                      }
                    : item
                )
              }
            : drawer)
      }
    case actionTypes.SET_DRAWER_ITEM:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.item._id
                    ? {
                        ...action.data.item
                      }
                    : item
                )
              }
            : drawer)
      }
    case actionTypes.ADD_DRAWER_ITEM_ATTACHMENT:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        attachments: [...item.attachments, action.data.attachment]
                      }
                    : item
                )
              }
            : drawer)
      }
    case actionTypes.SET_DRAWER_ITEM_ATTACHMENT:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        attachments: item.attachments.map(a =>
                          a._id === action.data.attachment._id
                            ? action.data.attachment
                            : a
                        )
                      }
                    : item
                )
              }
            : drawer)
      }
    case actionTypes.DELETE_DRAWER_ITEM_ATTACHMENT:
      return {
        ...state,
        drawers: state.drawers.map(drawer =>
          drawer._id === action.data.drawerId
            ? {
                ...drawer,
                items: drawer.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        attachments: item.attachments.filter(x => x._id !== action.data.attachmentId)
                      }
                    : item
                )
              }
            : drawer)
      }
    default:
      return state
  }
}
