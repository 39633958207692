import {
  addGroupMessage,
  setConnectionStatus,
  removeReaction,
  deleteUserFromGroupLocal,
  addMessageAttachments,
  getGroup,
  getGroupMessages,
  deleteGroup
} from '../state/actions/chat'
import { setContactPresence, getPresences } from '../state/actions/family'

import storeConfig from '../state/store'
import io from 'socket.io-client'
import config from '../config'
import { isEmpty } from 'lodash'

const [store] = storeConfig

export const CHAT_EVENTS = {
  ADD_ATTACHMENT: 'add_attachments',
  SEND_MESSAGE: 'send_message',
  JOIN_ROOM: 'join_room',
  LEAVE_ROOM: 'leave_room',
  SET_PRESENCE: 'set_presence',
  GROUP_CREATED: 'group_created',
  GROUP_DELETED: 'group_deleted',
  USER_CONNECTED: 'user_connected',
  DISCONNECT: 'disconnect',
  CONNECTED: 'connect',
  LOGOUT: 'logout',
  ADD_REACTION: 'add_reaction',
  REMOVE_REACTION: 'remove_reaction'
}

let socket

export default () => {
  const canReceiveMessage = (payload, userId) => {
    if (userId === payload.user && isEmpty(payload.message)) {
      return false
    }

    if (!payload.isGroup) {
      if (payload.user === userId) {
        return true
      }

      if (payload.receiver !== userId) {
        return false
      }
    }

    return true
  }

  if (socket) {
    return socket
  }
  const { dispatch, getState } = store
  const { setting: { room } } = getState()
  socket = io(`${config.wsUrl}`, {
    transports: ['websocket'],
    auth: {
      token: window.localStorage.getItem('token')
    }
  })

  socket.emit(CHAT_EVENTS.JOIN_ROOM, {
    groupId: room
  })

  socket.on('connect', () => {
    dispatch(setConnectionStatus(socket.connected))
  })

  socket.on('disconnect', () => {
    dispatch(setConnectionStatus(socket.connected))
  })

  socket.onAny(async (eventType, payload) => {
    switch (eventType) {
      case CHAT_EVENTS.GROUP_DELETED: {
        const { groupId } = payload
        dispatch(deleteGroup(groupId))
        break
      }
      case CHAT_EVENTS.SEND_MESSAGE: {
        const { chat: { groups }, profile: { _id: userId } } = getState()

        if (!canReceiveMessage(payload, userId)) {
          return
        }

        let group = groups.find(x => x._id === payload.chatroom)
        if (!group) {
          group = await dispatch(getGroup(payload.chatroom))
        }

        if (group) {
          socket.emit(CHAT_EVENTS.JOIN_ROOM, {
            userId: payload.user,
            groupId: `${room}/${payload.chatroom}`
          })
          dispatch(addGroupMessage(payload.chatroom, payload))
        }

        break
      }
      case CHAT_EVENTS.USER_CONNECTED: {
        const { isConnected, userId } = payload
        dispatch(setContactPresence(isConnected, userId))
        break
      }
      case CHAT_EVENTS.SET_PRESENCE: {
        const { isConnected, userId } = payload
        dispatch(setContactPresence(isConnected, userId))
        dispatch(getPresences())
        break
      }
      case CHAT_EVENTS.ADD_ATTACHMENT: {
        const { groupId, messageId, attachment } = payload
        const { chat: { messages } } = getState()
        if (!(groupId in messages)) {
          await dispatch(getGroup(payload.groupId))
        }
        const message = (messages[groupId] || []).find(x => x._id === messageId)
        if (!message) {
          await dispatch(getGroupMessages(groupId))
          return
        }
        dispatch(addMessageAttachments({ groupId, messageId, attachments: [attachment] }))
        break
      }
      case CHAT_EVENTS.LEAVE_ROOM: {
        const { userId, groupId } = payload
        dispatch(deleteUserFromGroupLocal(groupId, userId))
        break
      }
      case CHAT_EVENTS.REMOVE_REACTION: {
        const { groupId, messageId, reactionId } = payload
        const { chat: { messages } } = getState()

        if (!(groupId in messages)) {
          await dispatch(getGroup(payload.groupId))
        }
        const message = (messages[groupId] || []).find(x => x._id === messageId)
        if (!message) {
          await dispatch(getGroupMessages(groupId))
        }

        dispatch(removeReaction({
          groupId,
          messageId,
          reactionId
        }))

        break
      }
      case CHAT_EVENTS.ADD_REACTION: {
        const { groupId, messageId, reaction, user, reactionId } = payload
        const { chat: { messages } } = getState()

        if (!(groupId in messages)) {
          await dispatch(getGroup(payload.groupId))
        }
        const message = (messages[groupId] || []).find(x => x._id === messageId)
        if (!message) {
          await dispatch(getGroupMessages(groupId))
          return
        }

        dispatch({
          type: 'SET_REACTION',
          data: {
            groupId,
            messageId,
            reactionId,
            user,
            reaction
          }
        })
      }
    }
  })

  return socket
}
