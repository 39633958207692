import http from '../util/service'
import { clean } from '../util/index'
const path = 'family'

export const addFamily = (family) => http.post(path, clean(family))

export const updateFamily = async (family) => {
  try {
    return http.put(`${path}/${family.id}`, family)
  } catch (err) {
    return false
  }
}

export const getPresences = async () => {
  try {
    const { data } = await http.get(`${path}/presence`)
    return data
  } catch (err) {
    return false
  }
}

export const updateFamilyPhoto = async (id, file) => {
  try {
    /* eslint-disable no-undef */
    const formData = new FormData()
    formData.append('file', file)
    const { data: { url } } = await http.post(`${path}/${id}/photo`, formData)
    return url
  } catch (err) {
    return false
  }
}

export const deleteFamily = async (id) => {
  try {
    const { data } = await http.delete(`${path}/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export const getFamilies = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export const getFamilyMemberDetail = async (id) => {
  try {
    const { data } = await http.get(`${path}/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export default {
  addFamily,
  updateFamily,
  deleteFamily,
  getFamilies,
  getFamilyMemberDetail,
  updateFamilyPhoto,
  getPresences
}
