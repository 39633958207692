import http from '../util/service'

const path = 'setting'

export const getPlans = async () => {
  try {
    const { data } = await http.get(`${path}/plans`)
    return data
  } catch (err) {
    return false
  }
}

export const getCountries = async () => {
  try {
    const { data } = await http.get(`${path}/countries`)
    return data
  } catch (err) {
    return false
  }
}

export const getVaccinations = async () => {
  try {
    const { data } = await http.get(`${path}/vaccinations`)
    return data
  } catch (err) {
    return false
  }
}

export const getSocketNamespace = async () => {
  try {
    const { data } = await http.get(`${path}/namespace`)
    return data
  } catch (err) {
    return false
  }
}

export const getSettings = async () => {
  try {
    const { data } = await http.get(`${path}`)
    return data
  } catch (err) {
    return false
  }
}

export const updateLang = async (lang) => http.post(`${path}/lang`, { lang })

export default {
  getCountries,
  getPlans,
  getSettings,
  updateLang,
  getSocketNamespace,
  getVaccinations
}
