import * as actionTypes from '../actions/chat'

export default (state = {
  receipts: {},
  isConnected: false,
  groups: [],
  messages: {},
  currentGroup: ''
}, action) => {
  switch (action.type) {
    case actionTypes.SET_LAST_MESSAGE_READ:
      return {
        ...state,
        receipts: { ...state.receipts, [action.data.groupId]: action.data.messageId }
      }
    case actionTypes.GROUPS_RECEIVE:
      return {
        ...state,
        groups: action.data
      }
    case actionTypes.GROUP_RECEIVE: {
      return {
        ...state,
        groups: [...state.groups, action.data],
        messages: { ...state.messages, [action.data._id]: [] }
      }
    }
    case actionTypes.SET_GROUP:
      return {
        ...state,
        groups: state.groups.map(group =>
          group._id === action.data._id ? { ...group, ...action.data } : group)
      }
    case actionTypes.ADD_GROUP: {
      const exist = state.groups.filter(x => x._id === action.data._id).length > 0
      if (exist) {
        console.log('warning group exist from add_group', action.data.title, action.data._id)
        return state
      }

      return {
        ...state,
        groups: [...state.groups, action.data]
      }
    }
    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        messages: { ...state.messages, ...{ [action.data.id]: action.data.messages } }
      }
    case actionTypes.ADD_MESSAGE: {
      return {
        ...state,
        messages: { ...state.messages, [action.data.id]: [...state.messages[action.data.id] || [], action.data.message] }
      }
    }
    case actionTypes.DELETE_GROUP: {
      const m = { ...state.messages }
      const r = { ...state.receipts }
      delete m[action.data.id]
      delete r[action.data.id]

      return {
        ...state,
        groups: state.groups.filter(x => x._id !== action.data.id),
        messages: m,
        receipts: r
      }
    }
    case actionTypes.ADD_ATTACHMENT: {
      const m = state.messages[action.data.groupId].find(x => x._id === action.data.messageId)
      m.attachments = [...m.attachments, ...action.data.attachments]
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.data.groupId]: state.messages[action.data.groupId].map(x => {
            if (x._id === action.data.messageId) {
              return m
            }
            return x
          })
        }
      }
    }
    case actionTypes.SET_ATTACHMENT_TITLE: {
      const m = state.messages[action.data.groupId].find(x => x._id === action.data.messageId)
      m.attachments = m.attachments.map(a => {
        if (a._id === action.data.attachmentId) {
          a.title = action.data.title
        }
        return a
      })
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.data.groupId]: state.messages[action.data.groupId].map(x => {
            if (x._id === action.data.messageId) {
              return m
            }
            return x
          })
        }
      }
    }
    case actionTypes.DELETE_FROM_GROUP: {
      return {
        ...state,
        groups: state.groups.map(x => {
          if (x._id === action.data.groupId) {
            x.clients = x.clients.filter(c => c !== action.data.userId)
          }
          return x
        })
      }
    }
    case actionTypes.RECEIPTS_RECIEVE:
      return {
        ...state,
        receipts: action.data
      }

    case actionTypes.REMOVE_REACTION: {
      const m = state.messages[action.data.groupId].find(x => x._id === action.data.messageId)
      if (!('reactions' in m)) {
        m.reactions = []
      }

      m.reactions = m.reactions.filter(x => x._id !== action.data.reactionId)

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.data.groupId]: state.messages[action.data.groupId].map(x => {
            if (x._id === action.data.messageId) {
              return m
            }
            return x
          })
        }
      }
    }
    case actionTypes.SET_REACTION: {
      const m = state.messages[action.data.groupId].find(x => x._id === action.data.messageId)
      if (!('reactions' in m)) {
        m.reactions = []
      }

      const reaction = m.reactions.find(x => x._id === action.data.reactionId)
      if (reaction) {
        m.reactions = m.reactions.map(r => {
          if (r._id === action.data.reactionId) {
            r.reaction = action.data.reaction
          }
          return r
        })
      } else {
        m.reactions = [...m.reactions, {
          _id: action.data.reactionId,
          reaction: action.data.reaction,
          user: action.data.user
        }]
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.data.groupId]: state.messages[action.data.groupId].map(x => {
            if (x._id === action.data.messageId) {
              return m
            }
            return x
          })
        }
      }
    }
    case actionTypes.SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.data.groupId
      }
    default:
      return state
  }
}
