import * as actionTypes from '../actions/setting'

export default (state = {
  countries: [],
  plans: [],
  lang: 'en',
  usage: '0',
  capacity: 0,
  subscription: {},
  room: '',
  vaccinations: []
}, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_RECEIVE:
      return {
        ...state,
        lang: action.data.lang,
        usage: action.data.usage,
        subscription: action.data.subscription
      }
    case actionTypes.PLANS_RECEIVE:
      return {
        ...state,
        plans: action.data
      }
    case actionTypes.SET_LANG:
      return {
        ...state,
        lang: action.data
      }
    case actionTypes.COUNTRIES_RECEIVE:
      return {
        ...state,
        countries: action.data
      }
    case actionTypes.NAMESPACE_RECEIVE:
      return {
        ...state,
        room: action.data
      }
    case actionTypes.VACCINATIONS_RECEIVE:
      return {
        ...state,
        vaccinations: action.data.vaccinations
      }
    default:
      return state
  }
}
