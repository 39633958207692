export const DRAWERS_RECEIVE = 'DRAWERS_RECEIVE'
export const ADD_DRAWER = 'ADD_DRAWER'
export const SET_DRAWER = 'SET_DRAWER'
export const SET_DRAWER_TITLE_DESC = 'SET_DRAWER_TITLE_DESC'
export const SET_DRAWER_ITEM_TITLE = 'SET_DRAWER_ITEM_TITLE'
export const REMOVE_DRAWER = 'REMOVE_DRAWER'
export const REMOVE_DRAWER_ITEM = 'REMOVE_DRAWER_ITEM'
export const ADD_DRAWER_ITEM = 'ADD_DRAWER_ITEM'
export const SET_DRAWER_ITEM = 'SET_DRAWER_ITEM'
export const ADD_DRAWER_ITEM_ATTACHMENT = 'ADD_DRAWER_ITEM_ATTACHMENT'
export const SET_DRAWER_ITEM_ATTACHMENT = 'SET_DRAWER_ITEM_ATTACHMENT'
export const DELETE_DRAWER_ITEM_ATTACHMENT = 'DELETE_DRAWER_ITEM_ATTACHMENT'
export const UPDATE_DRAWER_TITLE_DESCRIPTION = 'UPDATE_DRAWER_TITLE_DESCRIPTION'
export const UPDATE_DRAWER_ITEM_TITLE = 'UPDATE_DRAWER_ITEM_TITLE'
export const UPDATE_DRAWER_ITEM_DESC = 'UPDATE_DRAWER_ITEM_DESC'

export const drawersReceive = (drawers) => ({
  type: DRAWERS_RECEIVE,
  data: drawers
})

export const updateDrawerTitleDesc = (id, { title, description }) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAWER_TITLE_DESCRIPTION,
      data: {
        id,
        title,
        description
      }
    })
  }
}

export const setDrawerItemTitle = (drawerId, itemId, title) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAWER_ITEM_TITLE,
      data: {
        drawerId,
        itemId,
        title
      }
    })
  }
}

export const setDrawerItemDesc = (drawerId, itemId, desc) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAWER_ITEM_DESC,
      data: {
        drawerId,
        itemId,
        desc
      }
    })
  }
}

export const listDrawers = () => {
  return async (dispatch, _, { drawer: drawerService }) => {
    const data = await drawerService.getDrawers()
    try {
      dispatch(drawersReceive(data))
    } catch (err) {
      return false
    }
  }
}

export const getLastDraft = () => {
  return async (dispatch, getState, { drawer: drawerService }) => {
    return drawerService.getLastDraft()
      .then(({ data }) => {
        const { drawer: { drawers } } = getState()
        const draft = drawers.find(x => x.status === 'draft')
        if (draft) {
          dispatch({
            type: REMOVE_DRAWER,
            data: {
              id: draft._id
            }
          })
        }
        dispatch({
          type: ADD_DRAWER,
          data
        })
        return data
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const getLastItemDraft = (drawerId) => {
  return async (dispatch, getState, { drawer: drawerService }) => {
    return drawerService.getLastItemDraft(drawerId)
      .then(({ data }) => {
        const { drawer: { drawers } } = getState()
        const drawer = drawers.find(x => x._id === drawerId)
        const draftItem = drawer.items.find(x => x.status === 'draft')
        if (draftItem) {
          dispatch({
            type: REMOVE_DRAWER_ITEM,
            data: {
              drawerId,
              itemId: draftItem._id
            }
          })
        }
        dispatch({
          type: ADD_DRAWER_ITEM,
          data: {
            drawerId,
            item: data
          }
        })
        return data
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const addDrawer = (drawer, edit) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.addDrawer(drawer)
      .then(({ data }) => {
        if (edit) {
          dispatch({
            type: SET_DRAWER,
            id: data._id,
            data
          })
        } else {
          dispatch({
            type: ADD_DRAWER,
            data
          })
        }
        return data
      })
      .catch(({ response: { data } }) => ({
        err: data.message,
        data: data.data
      }))
  }
}

export const deleteDrawer = (id) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.deleteDrawer(id)
      .then(() => {
        dispatch({
          type: REMOVE_DRAWER,
          data: {
            id
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const deleteDrawerItem = (drawerId, itemId) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.deleteDrawerItem(drawerId, itemId)
      .then(() => {
        dispatch({
          type: REMOVE_DRAWER_ITEM,
          data: {
            drawerId,
            itemId
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const updateDrawer = (id, title, description) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.updateDrawer(id, title, description)
      .then(({ title, description }) => {
        dispatch({
          type: SET_DRAWER_TITLE_DESC,
          data: {
            title,
            description
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const updateDrawerItemTitle = (drawerId, itemId, title) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.updateDrawerItemTitle(drawerId, itemId, title)
      .then(() => {
        dispatch({
          type: SET_DRAWER_ITEM_TITLE,
          data: {
            drawerId,
            itemId,
            title
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const addDrawerItem = (drawerId, drawerItemData) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    const task = drawerItemData._id
      ? drawerService.updateDrawerItem(drawerId, drawerItemData)
      : drawerService.addDrawerItem(drawerId, drawerItemData)

    return task.then(({ data }) => {
      dispatch({
        type: drawerItemData._id ? SET_DRAWER_ITEM : ADD_DRAWER_ITEM,
        data: {
          drawerId,
          item: data
        }
      })
      return data
    })
      .catch(() => false)
  }
}

export const addDrawerItemAttachment = (id, itemId, file, batchId) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.addDrawerItemAttachment(id, itemId, file, batchId)
      .then((data) => {
        dispatch({
          type: ADD_DRAWER_ITEM_ATTACHMENT,
          data: {
            drawerId: id,
            itemId,
            attachment: data
          }
        })
        return true
      })
      .catch(() => false)
  }
}

export const deleteDrawerItemAttachment = (drawerId, itemId, attachmentId) => {
  return async (dispatch, _, { drawer: drawerService }) => {
    return drawerService.deleteDrawerItemAttachment(drawerId, itemId, attachmentId)
      .then(() => {
        dispatch({
          type: DELETE_DRAWER_ITEM_ATTACHMENT,
          data: {
            drawerId,
            itemId,
            attachmentId
          }
        })
        return true
      })
      .catch(() => false)
  }
}
