import React from 'react'
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'

import { createBrowserHistory } from 'history'
import loadable from '@loadable/component'
import Loading from './components/common/loading'
import DashboardLoader from './components/loading/dashboardLoader'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import storeConfig from './state/store'

const [store, persistor] = storeConfig

const history = createBrowserHistory()
const Index = loadable(() => import('./pages'), { fallback: <Loading /> })
const Forgot = loadable(() => import('./pages/forgot'), { fallback: <Loading /> })
const RequestPassword = loadable(() => import('./pages/request-password'), { fallback: <Loading /> })
const Register = loadable(() => import('./pages/registerx'), { fallback: <Loading /> })
const Tos = loadable(() => import('./pages/tos'), { fallback: <Loading /> })
const Privacy = loadable(() => import('./pages/privacy'), { fallback: <Loading /> })
const Disclaimer = loadable(() => import('./pages/disclaimer'), { fallback: <Loading /> })
const Pricing = loadable(() => import('./pages/pricing'), { fallback: <Loading /> })
const ChangePassword = loadable(() => import('./pages/change-password'), { fallback: <Loading /> })

const Family = loadable(() => import('./pages/family'), { fallback: <DashboardLoader /> })
const FamilyAdd = loadable(() => import('./pages/family/add'), { fallback: <DashboardLoader /> })
const FamilyEdit = loadable(() => import('./pages/family/edit'), { fallback: <DashboardLoader /> })
const FamilyDetail = loadable(() => import('./pages/family/detail'), { fallback: <DashboardLoader /> })

const Drawer = loadable(() => import('./pages/drawer'), { fallback: <DashboardLoader /> })
const DrawerAdd = loadable(() => import('./pages/drawer/add'), { fallback: <DashboardLoader /> })
const DrawerEdit = loadable(() => import('./pages/drawer/edit'), { fallback: <DashboardLoader /> })
const DrawerItemsList = loadable(() => import('./pages/drawer/itemList'), { fallback: <DashboardLoader /> })
const DrawerItemAdd = loadable(() => import('./pages/drawer/itemAdd'), { fallback: <DashboardLoader /> })
const DrawerItemEdit = loadable(() => import('./pages/drawer/itemEdit'), { fallback: <DashboardLoader /> })

const Gallery = loadable(() => import('./pages/gallery'), { fallback: <DashboardLoader /> })
const GalleryAdd = loadable(() => import('./pages/gallery/add'), { fallback: <DashboardLoader /> })
const GalleryEdit = loadable(() => import('./pages/gallery/edit'), { fallback: <DashboardLoader /> })
const GalleryDetail = loadable(() => import('./pages/gallery/detail'), { fallback: <DashboardLoader /> })
const GalleryShare = loadable(() => import('./pages/gallery/share'), { fallback: <Loading /> })

const Account = loadable(() => import('./pages/account'), { fallback: <DashboardLoader /> })
const Support = loadable(() => import('./pages/support'), { fallback: <DashboardLoader /> })

const Jobs = loadable(() => import('./pages/jobs'), { fallback: <Loading /> })

const Chat = loadable(() => import('./pages/chat'), { fallback: <DashboardLoader /> })

export default () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router history={history}>
        <Switch>
          <Route exact path='/'><Index /></Route>
          <Route exact path='/cron'><Jobs /></Route>
          <Route exact path='/request-password'><RequestPassword /></Route>
          <Route exact path='/pricing'><Pricing /></Route>
          <Route exact path='/forgot'><Forgot /></Route>
          <Route exact path='/register'><Register /></Route>
          <Route exact path='/tos'><Tos /></Route>
          <Route exact path='/privacy'><Privacy /></Route>
          <Route exact path='/disclaimer'><Disclaimer /></Route>
          <Route exact path='/change-password'><ChangePassword /></Route>
          <Route exact path='/family'><Family /></Route>
          <Route exact path='/family/add'><FamilyAdd /></Route>
          <Route exact path='/family/:id/:userId' render={props => <FamilyDetail {...props} />} />
          <Route exact path='/family/:id/:userId/edit' render={props => <FamilyEdit {...props} />} />
          <Route exact path='/drawer'><Drawer /></Route>
          <Route exact path='/drawer/add'><DrawerAdd /></Route>
          <Route exact path='/drawer/:id' render={props => <DrawerItemsList {...props} />} />
          <Route exact path='/drawer/:id/edit' render={props => <DrawerEdit {...props} />} />
          <Route exact path='/drawer/:id/items/add' render={props => <DrawerItemAdd {...props} />} />
          <Route exact path='/drawer/:id/items/:itemId/edit' render={props => <DrawerItemEdit {...props} />} />
          <Route exact path='/gallery'><Gallery /></Route>
          <Route exact path='/gallery/add'><GalleryAdd /></Route>
          <Route exact path='/gallery/:id' render={props => <GalleryDetail {...props} />} />
          <Route exact path='/gallery/:id/edit'><GalleryEdit /></Route>
          <Route exact path='/share/:id'><GalleryShare /></Route>
          <Route exact path='/account'><Account /></Route>
          <Route exact path='/support'><Support /></Route>
          <Route exact path='/chat'><Chat /></Route>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
)
