import http from '../util/service'
const path = 'drawer'

export const getDrawers = async () => {
  try {
    const { data } = await http.get(path)
    return data
  } catch (err) {
    return false
  }
}

export const addDrawer = async (drawerData) => http.post(path, drawerData)
export const getLastDraft = async () => http.get(`${path}/get-last-draft`)
export const getLastItemDraft = async (drawerId) => http.get(`${path}/${drawerId}/get-last-item-draft`)

export const updateDrawer = async (drawerId, title, description) => {
  try {
    const { data } = await http.put(`${path}/${drawerId}`, { title, description })
    return data
  } catch (err) {
    return false
  }
}

export const deleteDrawerItem = async (id, itemId) => {
  try {
    const { data } = await http.delete(`${path}/${id}/items/${itemId}`)
    return data
  } catch (err) {
    return false
  }
}

export const addDrawerItem = async (drawerId, drawerItemData) => http.post(`${path}/${drawerId}`, drawerItemData)
export const updateDrawerItem = async (drawerId, drawerItemData) =>
  http.put(`${path}/${drawerId}/items/${drawerItemData._id}`, drawerItemData)

export const deleteDrawer = async (id) => {
  try {
    const { data } = await http.delete(`${path}/${id}`)
    return data
  } catch (err) {
    return false
  }
}

export const addDrawerItemAttachment = async (id, itemId, file, batchId) => {
  /* eslint-disable no-undef */
  const formData = new FormData()
  formData.append('file', file)
  formData.append('batchId', batchId)
  try {
    const { data } = await http.post(`${path}/${id}/items/${itemId}/attachments`, formData)
    return data
  } catch (err) {
    return false
  }
}

export const deleteDrawerItemAttachment = async (drawerId, itemId, attachmentId) => {
  try {
    await http.delete(`${path}/${drawerId}/items/${itemId}/attachments/${attachmentId}`)
    return true
  } catch (err) {
    return false
  }
}

export default {
  getDrawers,
  addDrawer,
  updateDrawer,
  addDrawerItem,
  deleteDrawerItem,
  deleteDrawer,
  updateDrawerItem,
  getLastDraft,
  addDrawerItemAttachment,
  deleteDrawerItemAttachment,
  getLastItemDraft
}
