import localStorage from 'local-storage'
export const LOGIN_RECEIVE = 'LOGIN_RECEIVE'

export const loginReceive = (profile) => ({
  type: LOGIN_RECEIVE,
  data: profile
})

export const confirmAccount = (email, token) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.confirmAccount(email, token)
      .then(() => true)
      .catch(({ response: { data: { message, data } } }) => {
        return {
          data,
          err: message
        }
      })
  }
}

export const login = (email, password) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.login(email, password)
      .then(({ data }) => {
        return data
      })
      .catch(({ response: { data: { message, data } } }) => {
        return {
          data,
          err: message
        }
      })
  }
}

export const requestPassword = (email) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.requestPassword(email)
      .then(({ data }) => {
        return true
      })
      .catch(({ response: { data: { message, data } } }) => {
        return {
          data,
          err: message
        }
      })
  }
}

export const changePassword = (email, password, token) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.changePassword(email, password, token)
      .then(({ data }) => {
        return true
      })
      .catch(({ response: { data: { message, data } } }) => {
        return {
          data,
          err: message
        }
      })
  }
}

export const verifyPasswordToken = (email, token) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.verifyPasswordToken(email, token)
      .then(() => true)
      .catch(() => false)
  }
}

export const registerUser = (data) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.registerUser(data)
      .then(({ data }) => {
        return data
      })
      .catch(({ response: { data: { message, data } } }) => ({
        data,
        err: message
      }))
  }
}

export const verifyCode = (code, verifiedId) => {
  return async (dispatch, _, { auth: authService }) => {
    return authService.verifyCode(code, verifiedId)
      .then(({ data }) => {
        localStorage('token', data.token)
        dispatch(loginReceive(data))
        return {
          isNewUser: data.isNewUser
        }
      })
      .catch(({ response: { data: { message } } }) => {
        return {
          err: message || 'Invalid Code'
        }
      })
  }
}

export const verifyToken = () => {
  return async (dispatch, _, { auth: authService }) => {
    if (!localStorage('token')) {
      return false
    }
    try {
      const response = await authService.verifyToken(localStorage('token'))
      return response
    } catch (err) {
      return false
    }
  }
}
