export const SHARE_LIST_RECEIVE = 'SHARE_LIST_RECEIVE'

export const listShared = (sharedId) => {
  return async (dispatch, _, { share: shareService }) => {
    try {
      const { data } = await shareService.listShared(sharedId)
      dispatch({
        type: SHARE_LIST_RECEIVE,
        data
      })
    } catch (err) {
      return false
    }
  }
}
