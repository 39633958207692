import * as actionTypes from '../actions/gallery'
import isEmpty from 'lodash/isEmpty'

export default (state = {
  galleries: []
}, action) => {
  switch (action.type) {
    case actionTypes.SET_SORT_INDEX:

      return {
        ...state,
        galleries: state.galleries.map(gallery => {
          const { sortOrder } = action.data
          const sort = sortOrder.find(x => x._id === gallery._id)
          if (sort) {
            return { ...gallery, sortIndex: sort.sortIndex }
          }
        }).filter(x => !isEmpty(x))
      }
    case actionTypes.GALLERIES_RECEIVE:
      return {
        ...state,
        galleries: action.data
      }
    case actionTypes.ADD_GALLERY:
      return {
        ...state,
        galleries: [...state.galleries, { ...action.data }]
      }
    case actionTypes.SET_GALLERY:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.id ? { ...gallery, ...action.data } : gallery)
      }
    case actionTypes.SET_GALLERY_TITLE:
      return {
        ...state,
        galleries: state.galleries.map(gallery => gallery._id === action.data.id
          ? {
              ...gallery,
              title: action.data.title
            }
          : gallery
        )
      }
    case actionTypes.SET_GALLERY_PERMISSION:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.id
            ? {
                ...gallery,
                viewPermissions: action.data.viewPermissions
              }
            : gallery
        )
      }
    case actionTypes.SET_GALLERY_STATUS:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.id
            ? {
                ...gallery,
                status: action.data.status
              }
            : gallery
        )
      }
    case actionTypes.REMOVE_GALLERY_ITEM:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.galleryId
            ? {
                ...gallery,
                items: gallery.items.filter(item => item._id !== action.data.itemId)
              }
            : gallery
        )
      }
    case actionTypes.ADD_GALLERY_ITEM:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.galleryId
            ? {
                ...gallery,
                items: [...gallery.items, action.data.item]
              }
            : gallery
        )
      }
    case actionTypes.REMOVE_GALLERY:
      return {
        ...state,
        galleries: state.galleries.filter(gallery => gallery._id !== action.data.id)
      }
    case actionTypes.SET_GALLERY_ITEM_FIELD:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery._id === action.data.galleryId
            ? {
                ...gallery,
                items: gallery.items.map(item =>
                  item._id === action.data.itemId
                    ? {
                        ...item,
                        [action.data.field]: action.data.value
                      }
                    : item
                )
              }
            : gallery
        )
      }
    default:
      return state
  }
}
