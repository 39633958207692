import React, { useState } from 'react'
import './profilePicture.scss'
import NoProfilePicture from '../../static/no-profile-pic.png'

export default ({ picture, name, size = 45 }) => {
  const [imageError, setImageError] = useState(false)

  const onImageError = () => {
    setImageError(true)
  }

  // const onImageLoaded = () => {
  //   setImageError(false)
  // }

  return (
    <div
      className='pp'
      style={{
        width: `${size}px`,
        height: `${size}px`
      }}
    >
      {picture && !name &&
        <img
          onError={onImageError}
          src={imageError ? NoProfilePicture : picture}
          alt='profile'
          style={{
            width: `${size}px`,
            height: `${size}px`
          }}
        />}
      {name && !picture &&
        <div
          className='pp__profileName'
          style={{
            width: `${size - 20}px`,
            height: `${size - 20}px`
          }}
        >
          {name.split(' ').map(s => s.charAt(0).toLocaleUpperCase())}
        </div>}
    </div>
  )
}
