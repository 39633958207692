import * as actionTypes from '../actions/auth'

export default (state = {
  profile: {}
}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_RECEIVE:
      return {
        ...state,
        profile: action.data
      }
    default:
      return state
  }
}
