export const SETTINGS_RECEIVE = 'SETTINGS_RECEIVE'
export const PLANS_RECEIVE = 'PLANS_RECEIVE'
export const SET_PLAN = 'SET_PLAN'
export const SET_LANG = 'SET_LANG'
export const SET_STORAGE_INFO = 'SET_STORAGE_INFO'
export const COUNTRIES_RECEIVE = 'COUNTRIES_RECEIVE'
export const NAMESPACE_RECEIVE = 'NAMESPACE_RECEIVE'
export const VACCINATIONS_RECEIVE = 'VACCINATIONS_RECEIVE'

export const getPlans = () => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.getPlans()
      dispatch({
        type: PLANS_RECEIVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getCountries = () => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.getCountries()
      dispatch({
        type: COUNTRIES_RECEIVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getVaccinations = () => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.getVaccinations()
      dispatch({
        type: VACCINATIONS_RECEIVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getSocketNamespace = () => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const { namespace } = await settingService.getSocketNamespace()
      dispatch({
        type: NAMESPACE_RECEIVE,
        data: namespace
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getSettings = () => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.getSettings()
      dispatch({
        type: SETTINGS_RECEIVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const updatePlan = (planId) => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.updatePlan(planId)
      dispatch({
        type: SET_PLAN,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const createSubscription = (planId, userId) => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      const data = await settingService.createSubscription(planId, userId)
      dispatch({
        type: SET_PLAN,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const updateLang = (lang) => {
  return async (dispatch, _, { setting: settingService }) => {
    try {
      await settingService.updateLang(lang)
      dispatch({
        type: SET_LANG,
        data: lang
      })
      return true
    } catch (err) {
      return false
    }
  }
}
