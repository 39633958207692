import http from '../util/service'
const path = 'auth'

export const login = async (email, password) => {
  return http.post(`${path}/login`, {
    email,
    password
  })
}

export const registerUser = async (data) => {
  return http.post(`${path}/register`, data)
}

export const requestPassword = async (email) => {
  return http.post(`${path}/request-password`, { email })
}

export const changePassword = async (email, password, token) => {
  return http.post(`${path}/change-password`, { email, password, token })
}

export const verifyPasswordToken = async (email, token) => {
  return http.post(`${path}/verify-password-token`, { email, token })
}

export const verifyCode = async (code, verifiedId) => {
  return http.post(`${path}/verify-code`, {
    totp: code,
    id: verifiedId
  })
}

export const confirmAccount = async (email, token) => {
  return http.post(`${path}/verify-otp`, {
    email,
    token
  })
}

export const verifyToken = async (token) => http.head(`${path}/verify-jwt`)

export default {
  login,
  registerUser,
  verifyToken,
  verifyCode,
  requestPassword,
  verifyPasswordToken,
  changePassword,
  confirmAccount
}
