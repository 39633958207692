export const GROUPS_RECEIVE = 'GROUPS_RECEIVE'
export const GROUP_RECEIVE = 'GROUP_RECEIVE'
export const SET_GROUP = 'SET_GROUP'
export const ADD_GROUP = 'ADD_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS'
export const SET_LAST_MESSAGE_READ = 'SET_LAST_MESSAGE_READ'
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT'
export const SET_ATTACHMENT_TITLE = 'SET_ATTACHMENT_TITLE'
export const DELETE_FROM_GROUP = 'DELETE_FROM_GROUP'
export const RECEIPTS_RECIEVE = 'RECEIPTS_RECIEVE'
export const SET_REACTION = 'SET_REACTION'
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP'
export const REMOVE_REACTION = 'REMOVE_REACTION'

export const addMessageAttachments = ({ groupId, messageId, attachments }) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_ATTACHMENT,
      data: {
        groupId,
        messageId,
        attachments
      }
    })
  }
}

export const addReaction = ({ messageId, reaction, groupId, userId }) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      await chatService.addReaction(messageId, reaction)
    } catch (error) {
      return false
    }
  }
}

export const removeReaction = ({ messageId, groupId, reactionId }) => {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_REACTION,
      data: {
        messageId, groupId, reactionId
      }
    })
  }
}

export const getGroupMessages = (id, force = true) => {
  return async (dispatch, getState, { chat: chatService }) => {
    if (!force) {
      const { chat: { messages } } = getState()
      const msgs = messages[id] || []
      if (msgs.length > 0) {
        return []
      }
    }

    try {
      const data = await chatService.getGroupMessages(id)
      dispatch({
        type: SET_MESSAGE,
        data: {
          id,
          messages: data
        }
      })
      return data
    } catch (err) {
      return false
    }
  }
}

export const getGroups = (force = true) => {
  return async (dispatch, getState, { chat: chatService }) => {
    if (!force) {
      const { chat: { groups = [] } } = getState()
      if (groups.length > 0) {
        return []
      }
    }

    try {
      const data = await chatService.getGroups()
      dispatch({
        type: GROUPS_RECEIVE,
        data: data || []
      })
      return data
    } catch (err) {
      return false
    }
  }
}

export const getGroup = (id) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.getGroup(id)
      dispatch({
        type: GROUP_RECEIVE,
        data
      })
      return data
    } catch (err) {
      return false
    }
  }
}

export const setLastMessageRead = (groupId, messageId) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.setLastMessageRead(groupId, messageId)
      dispatch({
        type: SET_LAST_MESSAGE_READ,
        data: {
          groupId: data.groupId,
          messageId: data.messageId
        }
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const getReadReceipts = () => {
  console.log('get receipts')
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.getReadReceipts()
      dispatch({
        type: RECEIPTS_RECIEVE,
        data
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const updateAttachmentTitle = ({ groupId, messageId, attachmentId, title }) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.updateAttachmentTitle({ groupId, messageId, attachmentId, title })
      dispatch({
        type: SET_ATTACHMENT_TITLE,
        data: {
          groupId,
          messageId,
          attachmentId,
          title
        }
      })
      return data
    } catch (err) {
      return false
    }
  }
}

export const setConnectionStatus = (isConnected) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONNECTION_STATUS,
      data: {
        isConnected
      }
    })
  }
}

export const setCurrentGroup = (groupId) => {
  console.log(groupId)
  return async (dispatch) => {
    dispatch({
      type: SET_CURRENT_GROUP,
      data: {
        groupId
      }
    })
  }
}

export const saveGroup = (group) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.saveGroup(group)
      dispatch({
        type: group.id ? SET_GROUP : ADD_GROUP,
        data: data
      })
      return data
    } catch (err) {
      return false
    }
  }
}

export const addGroupLocal = (group) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_GROUP,
      data: group
    })
  }
}

export const addGroupMessage = (groupId, message) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      data: {
        id: groupId,
        message
      }
    })
  }
}

export const createEmptyMessage = (groupId) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const message = await chatService.createEmptyMessage(groupId)
      dispatch({
        type: ADD_MESSAGE,
        data: {
          id: groupId,
          message
        }
      })
      return message
    } catch (err) {
      return false
    }
  }
}

export const addAttachment = (id, file) => {
  return async (dispatch, _, { chat: chatService }) => {
    try {
      const data = await chatService.addAttachment(id, file)
      return data
    } catch (err) {
      return false
    }
  }
}

export const deleteGroup = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_GROUP,
      data: {
        id
      }
    })
  }
}

export const deleteUserFromGroupLocal = (groupId, userId) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_FROM_GROUP,
      data: {
        groupId,
        userId
      }
    })
  }
}
