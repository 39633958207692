import family from './family'
import gallery from './gallery'
import drawer from './drawer'
import attachment from './attachment'
import auth from './auth'
import profile from './profile'
import setting from './setting'
import faq from './faq'
import job from './job'
import share from './share'
import message from './message'
import chat from './chat'
import vaccination from './vaccination'

export default {
  chat,
  family,
  gallery,
  drawer,
  attachment,
  auth,
  profile,
  setting,
  faq,
  job,
  share,
  message,
  vaccination
}
